<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: "App",
};
</script>

<style lang="scss">

// Import Main styles for this application
@import "assets/scss/style";

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600");
body,
html {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}
body {
    font-family: "Verdana", Geneva, sans-serif;
    font-size: 18px;
    background-color: #f2f4f7;
    color: #262626;
}

#app {
    font-family: "Verdana", Geneva, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
</style>
