import Vue from "vue";
import Router from "vue-router";

// Containers
const TheContainer = () => import("@/views/TheContainer");
const Login = () => import("@/views/pages/Login");

// Views
const Dashboard = () => import("@/views/dashboard/Dashboard");

// Dirt Cheap
const WooCommerce = () => import("@/views/woocommerce/WooCommerce");
const Tickets = () => import("@/views/ticket/Tickets");
const NewTicket = () => import("@/views/ticket/NewTicket");
const EditTicket = () => import("@/views/ticket/EditTicket");

const Retails = () => import("@/views/retail/Retails");

const Customers = () => import("@/views/customer/Customers");
const NewCustomer = () => import("@/views/customer/NewCustomer");
const EditCustomer = () => import("@/views/customer/EditCustomer");
const ViewCustomer = () => import("@/views/customer/ViewCustomer");

const Products = () => import("@/views/product/Products");
const NewProduct = () => import("@/views/product/NewProduct");
const EditProduct = () => import("@/views/product/EditProduct");

const Reports = () => import("@/views/reports/Reports");
const SMS = () => import("@/views/sms/SMS");
const Settings = () => import("@/views/setting/Settings");
const SettingsDirtCheap = () => import("@/views/setting-dirtcheap/SettingsDirtCheap");

Vue.use(Router);

export default new Router({
    mode: "history",
    linkActiveClass: "active",
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes(),
});

function configRoutes() {
    return [
        {
            path: "/",
            redirect: "/woocommerce",
            name: "TheContainer",
            component: TheContainer,
            // beforeEnter(to, from, next) {
            //     let token = localStorage.getItem("api_token");
            //     if (!token || token.trim().length == 0) {
            //         next("/login");
            //     } else {
            //         next();
            //     }
            // },
            children: [
                {
                    path: "woocommerce",
                    name: "WooCommerce",
                    component: WooCommerce,
                },
                {
                    path: "dashboard",
                    name: "Dashboard",
                    component: Dashboard,
                },

                {
                    path: "tickets",
                    name: "Tickets",
                    component: Tickets,
                },
                {
                    path: "new-ticket",
                    name: "NewTicket",
                    component: NewTicket,
                },
                {
                    path: "edit-ticket/:id",
                    name: "EditTicket",
                    component: EditTicket,
                },
                {
                    path: "retails",
                    name: "Retails",
                    component: Retails,
                },
                {
                    path: "customers",
                    name: "Customers",
                    component: Customers,
                },
                {
                    path: "new-customer",
                    name: "NewCustomer",
                    component: NewCustomer,
                },
                {
                    path: "edit-customer/:id",
                    name: "EditCustomer",
                    component: EditCustomer,
                },
                {
                    path: "view-customer/:id",
                    name: "ViewCustomer",
                    component: ViewCustomer,
                },
                {
                    path: "products",
                    name: "products",
                    component: Products,
                },
                {
                    path: "new-product",
                    name: "NewProduct",
                    component: NewProduct,
                },
                {
                    path: "edit-product/:id",
                    name: "EditProduct",
                    component: EditProduct,
                },
                {
                    path: "reports",
                    name: "Reports",
                    component: Reports,
                },
                {
                    path: "sms",
                    name: "SMS",
                    component: SMS,
                },
                {
                    path: "settings",
                    name: "Settings",
                    component: Settings,
                },
                {
                    path: "settings-dirtcheap",
                    name: "SettingsDirtCheap",
                    component: SettingsDirtCheap,
                },
            ],
        },
        {
            path: "/login",
            name: "Login",
            component: Login,
        },
    ];
}
